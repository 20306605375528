import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';

const ServicesSection = () => {
  const servicesData = useStaticQuery(graphql`
    {
      allContentfulServices {
        nodes {
          whatWeDo {
            title
            description
            serviceIcon {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  const servicesSectionData = servicesData.allContentfulServices.nodes[0].whatWeDo;

  return (
    <section className="flex flex-wrap py-28 relative w-full">
      <div className="md:  xl:px-[3.5rem] container mx-0 md:mx-auto pt-4 px-3 relative">
        <div className="place-items-center flex-wrap text-center">
          <div className="grid grid-cols-1">
            <div className="mb-10 w-full">
              <div className="text-left md:text-center font-futura text-primary block text-md tracking-1 uppercase w-full mb-md">CUSTOM SOLUTIONS WE PROVIDE</div>
              <div className="text-left md:text-center md:text-36px md:leading-1.3 font-futura text-[1.8rem] mx-0 md:mx-auto font-semibold block">
                <p>Customer-focused solutions that</p>
                <p>run on all devices. Anywhere.</p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center content-center gap-y-9">
            {servicesSectionData.map(({
              title,
              description,
              serviceIcon,
            }) => (
              <div key={title} className="flex flex-col px-8 lg:w-1/3 md:w-6/12">
                <div className="flex flex-wrap w-full flex-1">
                  <figure className="items-center flex justify-start md:justify-center mb-md w-full">
                    <GatsbyImage imgStyle={{ objectFit: 'contain' }} className="flex flex-wrap !object-contain items-center h-5-0 w-5-0 md:h-5-1 md:w-5-1" image={serviceIcon.gatsbyImageData} alt="app development" />
                  </figure>
                  <div className="text-left md:text-center  font-futura w-full font-semibold">{title}</div>
                  <p className="text-left md:text-center  font-futura block font-light px-0 md:px-3xl w-full ">{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
